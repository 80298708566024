body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-thead > tr > th{
  background: #F2E2E2 !important;
}

.button-link-grey {
  color: #8C8C8C !important;
  font-weight: 700 !important;
}

.button-link-red {
  color: #A8071A !important;
  font-weight: 700 !important;
}

.button-link-grey-border {
  color: #000000 !important;
  border-style: solid;
  border-width: 2px;
  border-color: #D9D9D9 !important;
}

.button-link-black{
  color: #000000 !important;
  border-color: #D9D9D9 !important;
  background-color: transparent !important;
}