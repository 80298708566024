.ant-menu-submenu-active, .ant-menu-submenu-selected, .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow, .ant-menu-item-active, .ant-menu-item-selected {
  color: #A8071A !important;
}

.ant-menu-item-selected{
  font-weight: 500;
}

.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #A8071A !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #F2E2E2;
}