body{
  background-color: #A8071A;
}

.styRightSideLogin {
  background-color: #A8071A;
  background-image: url('@assets/login/background.svg');
  height: 50vh;
}

.styLoginCard{
  border-color: #A8071A;
}

.styLoginCard > .ant-card-body{
  padding:0;
}

.styLeftSideLogin{
  padding:20px;
}

.styLoginCol{
  top:25vh;
}

.stySpace{
  width:100%;
}

.stySpace > .ant-space-item{
  width:100%;
}