.styNavbar {
  background-color: #A8071A !important;
  height: 10vh;
  min-height: 90px;
}

.ant-page-header-content{
  padding-top: 0;
}

.styName{
  margin-bottom: 0 !important;
  color:white !important;
}

.styIdentityCol {
  text-align: end !important;
  line-height: 4;
}